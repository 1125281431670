import React, { useState } from 'react'


export default function Loadjson(props) {

    const [title, setTitle] = useState([]);
    const { value:username, bind:bindUsername, reset:resetUsername } = useState('');
    const { value:head, bind:bindHead, reset:resetHead } = useState('');
    const { value:content, bind:bindContent, reset:resetContent } = useState('');

    const getData = async () => {
        const url = 'https://worker.shubhampatni88.workers.dev/'
        const resp = await fetch(url, {
            method: "POST",
            body: JSON.stringify(),
            headers: { 'Content-type': 'application/json' }
        }).then((resp) => resp.json()).then((data) => { setTitle(data) })

    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        alert(`Submitting Name ${username} ${head} ${content}`);
        resetUsername();
        resetHead();
        resetContent();
    }
  

    return (
        <div>
            <h1 className="pad-5 mt-0">Hello!! Press "View Posts" button to view Posts </h1>

            <button className="mt-2 " onClick={getData}>View Posts</button>
            {title.map(function (info) {
                return <div id="myDiv" className="flex-row justify-content-start margin-x-10 border-white-rounded margin-y-2"><h4 id="myDiv" className="pad-2 border-white1  margin-0">by : {info.username}</h4><h2 className="pad-2 margin-0">{info.title}</h2 ><p className="pad-2 margin-0">{info.content}</p></div>
            })}

            <form onSubmit={handleSubmit}>
            <div id="myDiv" className=" flex-row justify-content-start margin-x-10 border-white-rounded margin-y-2"> <h2 id="myDiv" className="pad-2 border-white1  margin-0">Add your own Post</h2>
                <div className="pad-2 margin-0">
                        <label className="pad-y-2">Enter Username : <input type="text" {...bindUsername} placeholder="Enter Username" /> </label>
                        <label className="pad-y-2">Enter Title : <input type="text" {...bindHead} placeholder="Enter Title" /> </label>
                        <label className="pad-y-2">Enter Content : <input type="text" {...bindContent} placeholder="Enter Content" /> </label>
                        <input className="mt-2 " type="submit" value="Submit" />
                </div>
                
            </div>
            </form>
        </div>
    )
}
