import './App.css';
import Loadjson from './components/Loadjson';
import Darkmode from './components/Darkmode';
import React, { useState } from 'react'

function App() {

  const [palette,setPalette] = useState('invisible') ;

  const [mode, setMode] = useState('Enable DarkMode');
    
    const [dark, setDark] = useState({
        color: 'black',
        backgroundColor: 'white',
        borderColor:'black',
        
    })   

const handleDark = () => {

  if(mode === "Enable DarkMode"){
      let newMode = "Disable DarkMode";
      setMode(newMode);
      document.body.style.backgroundColor = "rgb(39,50,78)"; 
      document.body.style.color = "white"; 
      document.getElementById("myDiv").style.borderColor = "white";
     const newDark = {color: 'white',
        backgroundColor: 'rgb(39,50,78)',
        border: '1px solid white',
        borderColor: 'white',
        
    }
      setDark(newDark)
      setPalette('visible');
  }else{
      let newMode = "Enable DarkMode";
      setMode(newMode);
      document.body.style.backgroundColor = "white"; 
      document.body.style.color = "black"; 
      document.getElementById("myDiv").style.borderColor = "black";
      const newDark = {
          color: 'black',
          backgroundColor: 'white',
          borderColor: 'black',
      }
      setDark(newDark)
      setPalette('invisible');
  }
}

const red = () => {

  if(mode === "Disable DarkMode"){
      
      document.body.style.backgroundColor = "#440808"; 
     const newDark = {color: 'white',
        backgroundColor: '#440808',
        border: '1px solid white',
        
    }
      setDark(newDark)
      setPalette('visible');
  }
}

const green = () => {

  if(mode === "Disable DarkMode"){
      document.body.style.backgroundColor = "#215936"; 
     const newDark = {color: 'white',
        backgroundColor: '#215936',
        border: '1px solid white',
        
    }
      setDark(newDark)
      setPalette('visible');
  }
}

const purple = () => {

  if(mode === "Disable DarkMode"){
      
      document.body.style.backgroundColor = "#4D005B"; 
     const newDark = {color: 'white',
        backgroundColor: '#4D005B',
        border: '1px solid white',
        
    }
      setDark(newDark)
      setPalette('visible');
  }
}

  return (
    <div className="App">
        <Darkmode handleDark={handleDark}  mode={mode} palette={palette} red={red} green={green} purple={purple}></Darkmode>
        <Loadjson/>

    </div>
  );
}

export default App;
